<template>
  <div class="container" >    
    <div v-if="JSON.stringify(obj)!=='{}'">
      <div class="text-center">
          <Organizer @done="done" ref="con2" />
      </div>   
    </div>
        <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">
              <b-spinner  variant="primary" label="Text Centered"></b-spinner>
              <strong >Loading....</strong>
          </div>
        </b-modal> 

        <b-alert
          v-model="showTop"
          class="position-fixed fixed-top m-0 rounded-0"
          style="z-index: 2000;"
          variant="success"
          dismissible
        >
          更新成功
        </b-alert>              

  </div>        
</template>
<script>
import { mapState } from "vuex";
import Organizer from '../../act/Organizer'

export default {
  name: 'Org_generate',   
  components: {
    Organizer,
  },

  data() {
    return {
      obj: {},
      rsdata:null,
      showTop:false,
      loading: false,   
                               
            };
  },
  mounted(){
    this.obj=this.datas.find(item => item.id == this.defaultval.id)
  },
  computed: {
    ...mapState("account", ["status", "user"]), 
    ...mapState("makeorg", ["datas","defaultval"]),
  }, 
  watch: {
      defaultval(val){
          this.obj=this.datas.find(item => item.id == val.id)
      },
  },
  methods: {   
    done(rs){
        this.rsdata = rs
        this.showTop = true;
        setTimeout(() => {                           
          this.showTop = false
          clearInterval();
        }, 2000);
    },
    onReset(event) {
        event.preventDefault()
        this.$nextTick(() => {

        })
    },  
 
    onHide(evt) {
        if(evt.trigger === "backdrop"){
            evt.preventDefault();
        }
    }, 
    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },                              
  },
};
</script>
<style>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #535151;;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #535151;
}
.divhidden{
  visibility:hidden;
}
.frams{
  cursor: pointer;
  width:685px;
  height:230px;
  background-color: #bdcbe2;
  border-style:dotted;
  border-width:2px;
  border-radius: 17px;
  border-color:#385e97;
}
.disabled-row {
  background-color: #f1de6e;
  pointer-events: none;
}
.checkboxcss{
  background-color:#f0f2f5;
  margin:6px;
  padding:5px;
  border-style:dotted;
  border-width:1px;
  border-color:#d0d2d5;
}

</style>


